<template>        
    <footer v-if="active_level_short == 'beneficiario'" id="hull" class="footer-site">
    
        <div class="container">
            <div class="logo_footer">
                <img :src="strapi_url + footer.logo_footer.data.attributes.url" alt="logo">
            </div>
            <div class="ftgrid">
                <div class="left">                   
                    <ComponentList v-if="collumn1" :components="collumn1"></ComponentList>                                       
                </div>
                <div class="right">                    
                    <ComponentList v-if="collumn2" :components="collumn2"></ComponentList>   
                </div>
                
            </div>
        </div>
        <div class="copyright">
            <div class="center-content t-centered">
                <h4 class="t-16 t-lh-40 t-white t-reg description_footer" v-html="footer.coypright"></h4>
                <a href="https://www.gov.br/ans/pt-br" target="_blank" class="registro"><img src="@/images/registro.png" alt="registro ans amil one" width="100"></a>
            </div>
        </div>
    </footer>
    <footer v-else id="hull" class="footer-site">
        <div class="container">       
            <div class="logo_footer">
                <img :src="strapi_url + footer.logo_footer.data.attributes.url" alt="logo">
            </div>     
            <div class="ftgrid">
                <div class="left">                   
                    <h2 class="title-form" v-html="titleFormBreaked"></h2>
                    <p class="t-16 t-lh-40 t-white t-reg" v-html="footer.intro_form"></p>
                    <form action="#" @submit.prevent="submitForm()" class="form-footer" method="post" ref="formFooter">
                        <div class="form-group">
                            <div class="input-group">
                            	<label for="nomeInp" hidden>Nome</label> 
                                <input type="text" placeholder="Nome" name="name" aria-label="Digite o nome" id="nomeInp" required>
                            </div>
                            <div class="input-group">
                                <label for="phoneInp" hidden>Digite o Telefone</label>
                                <input type="text" placeholder="Telefone" name="phone" aria-label="Digite o telefone" id="phoneInp" v-mask="['(##) ####-####', '(##) #####-####']" required>
                                
                                <label for="emailInp" hidden>Digite o e-mail</label>
                                <input type="text" placeholder="Email" name="email" aria-label="Digite o e-mail" id="emailInp" required>                        
                            </div>
                            <div class="input-group">
                            	<label for="estados" hidden>Selecione seu estado</label>
                                <select name="estado" id="estados" aria-label="Selecione seu estado" required v-model="estado_val">                        
                                    <option value="" disabled="true" selected>Selecione seu estado</option>
                                    <option v-for="estado in estados" :value="estado.uf">{{estado.estado}}</option>
                                </select>
                                
                                <template v-if="!estado_val || estados_val === ''">
                                    <input type="checkbox" class="possui-cnpj" name="possui_cnpj" @change="changeHasCNPJ()" id="possui_cnpj" :checked="cnpj">
                                    <label for="possui_cnpj">Possui CNPJ</label> 
                                </template>
                                             
                            </div>
                            <div class="input-group" v-if="estado_val">
                            	<label for="cidades" hidden>Selecione sua cidade</label>
                                <select name="cidade" id="cidades" aria-label="Selecione sua cidade" required autocomplete="off" @change="changeCidadeRegiaoID($event)" v-model="cidade_val">                        
                                    <option value="" disabled="true" selected>Selecione sua cidade</option>
                                    <option v-for="cidade in cidades" :value="cidade.município">{{cidade.município}}</option>
                                </select>                        
                                


                                <input type="checkbox" class="possui-cnpj" name="possui_cnpj" @change="changeHasCNPJ()" id="possui_cnpj" :checked="cnpj">
                                <label for="possui_cnpj">Possui CNPJ</label>                        
                            </div>

                            <input type="hidden" name="cidade_id" v-model="cidade_id" />
                            <input type="hidden" name="regiao_id" v-model="regiao_id" />

                            <div class="input-group">
                                <label for="cnpjcpf" hidden>Digite o CPF/CNPJ</label>
                                <input aria-label="Digite o CNPJ ou CPF" id="cnpjcpf" type="text" :placeholder=" cnpj ? 'CNPJ' : 'CPF'" :name=" cnpj ? 'cnpj' : 'cpf'" v-mask="cnpj ? '##.###.###/####-##':'###.###.###-##'" required v-model="document">
                            </div>
                            <div class="input-group aceite">                                
                                <input type="checkbox" id="aceite" name="aceite" required> 
                                <label for="aceite"></label>
                                <p>Autorizo a AMIL a coletar, registrar e armazenar os dados pessoais indicados nesse formulário para o propósito de enviar melhores ofertas de produtos que comercializa, assim como a compartilhar meus dados pessoais exclusivamente com as empresas contratadas para este fim e desejo receber mensagens de marketing e ofertas de produtos da Amil e de seus parceiros de negócio <strong style="color: #d5086e">*</strong> </p>
                            </div> 
                            <div class="input-submit input-group">
                                <p><strong style="color: #d5086e">*</strong> Campos obrigatórios</p>
                                <input type="hidden" name="campaign" id="input-campaign">
                                <input type="hidden" name="medium" id="input-medium">
                                <input type="hidden" name="origin" id="input-origin">
                                <input type="hidden" name="idGA_lead" id="idGA_lead" />
                                <button type="submit" aria-label="Enviar Formulário Amil One" id="formSubmitBtn">Enviar</button>
                            </div> 
                        </div>
                    </form>
                </div>
                <div class="right">                    
                    <ComponentList v-if="collumn2_beneficiario" :components="collumn2_beneficiario"></ComponentList>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="center-content t-centered">
                <h4 class="t-16 t-lh-40 t-white t-reg" v-html="footer.coypright"></h4>
                <a href="https://www.gov.br/ans/pt-br" target="_blank" class="registro"><img src="@/images/registro.png" alt="registro ans amil one" width="100"></a>
            </div>
        </div>
    </footer>   
</template>
<script>
    import axios from "axios";    
    import ComponentList from '../components/ComponentList';
    import estados from '../assets/estados.json';
    import cidades_json from '../assets/cidades.json';
    //import from vue-markdown-it
    import VueMarkdownIt from 'vue-markdown-it';
    import { useToast } from 'vue-toastification';
    import {TheMask} from 'vue-the-mask'
    export default {
        name: 'FooterSite',
        components: {            
            ComponentList, 
            VueMarkdownIt,
            TheMask         
        },               
        data() {
            return {
                active_level: 'Visitante',
                strapi_url: process.env.VUE_APP_STRAPI_URL,
                footer: [],
                collumn1: [],
                collumn2: [],
                collumn2_beneficiario: [],
                estados: estados,
                cidades: [],
                estado_val: '',
                regiao_id: '',
                cidade_id: '',
                cnpj: false,
                document: ''
            }
        }, 
        watch: {
            estado_val() {
                const uf = this.estado_val;
                this.cidades = uf !== "" ? cidades_json.filter(cidades => cidades.uf=== uf) : [];
                this.cidade_val = ''
                this.regiao_id = ''
                this.cidade_id = ''
            }
        },
        computed: {
            titleFormBreaked() {
                return this.footer.title_form.replace(/\n/g, '<br/>');
            }
        },        
        methods: {
            getCookie(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            getUTMzz() {
                if (this.getCookie("__utmzz")) {
                    var utms = this.getCookie("__utmzz");
                    var utmsA = utms.split("|");
                    var utmsFinal = [];
                    utmsA.forEach(function (el) {
                        var splitedEl = el.split("=");
                        utmsFinal[splitedEl[0]] = splitedEl[1];
                    });

                    document.querySelector('input[name="campaign"]').value = utmsFinal["utmccn"];
                    document.querySelector('input[name="medium"]').value = utmsFinal["utmcmd"];
                    document.querySelector('input[name="origin"]').value = utmsFinal["utmcsr"];
                }
            },
            create_UUID() {
                let dt = new Date().getTime();
                const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    const r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            getUUID() {
                let uuid = localStorage.getItem('uuid');
                if (!uuid) {
                    uuid = this.create_UUID();
                    localStorage.setItem('uuid', uuid);
                }
                return uuid;
            },
            submitForm() {   
                const submitButton = document.getElementById('formSubmitBtn');             
                submitButton.setAttribute("disabled", "disabled");

                const toast = useToast(); 
                const form = this.$refs.formFooter;

                if (!this.validateDocument()) {
                    toast.error(`${this.cnpj ? 'CNPJ' : 'CPF'} inválido!`);
                    submitButton.removeAttribute("disabled", "disabled");
                    return false;
                }
                
                let idGA_lead = document.getElementById('idGA_lead').value;                
                
                const formData = new FormData(form);               
                
                axios.post(`${this.strapi_url}/api/footer/submitForm`, formData).then(response => {
                    dataLayer.push({
                        'event': 'send', 
                        'idGA_lead': idGA_lead
                    });
                    toast.success('Formulário enviado com sucesso!');
                    submitButton.removeAttribute("disabled", "disabled");
                    form.reset();
                }).catch(error => {
                    toast.error('Erro ao enviar formulário!');
                    submitButton.removeAttribute("disabled", "disabled");
                });
                return false;
                
            },
            validateDocument() {
                if (this.cnpj) {
                    if (!this.validateCNPJ(this.document)) {
                        return false;
                    }
                } else {
                    if (!this.validateCPF(this.document)) {
                        return false;
                    }
                }
                return true;
            },
            validateCNPJ(cnpj) {
                cnpj = cnpj.replace(/\D/g, '');

                if (cnpj.length != 14) {
                    return false;
                }

                if (/^(\d)\1{13}$/.test(cnpj)) {
                    return false;
                }

                function validaDigitoCnpj(tamanho) {
                    let numeros = cnpj.substring(0, tamanho);
                    let digitos = cnpj.substring(cnpj.length - 2);
                    let soma = 0;
                    let pos = tamanho - 7;
                    for (let i = tamanho; i >= 1; i--) {
                        soma += numeros.charAt(tamanho - i) * pos--;
                        if (pos < 2) {
                            pos = 9;
                        }
                    }
                    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                    if (resultado != digitos.charAt(tamanho - 12)) {
                        return false;
                    }
                    return true;
                }

                if (!validaDigitoCnpj(cnpj.length - 2) || !validaDigitoCnpj(cnpj.length - 1)) {
                    return false;
                }
                return true;
            },
            validateCPF(cpf) {
                cpf = cpf.replace(/\D/g, '');

                if (cpf.length != 11) {
                    return false;
                }

                if (/^(\d)\1{10}$/.test(cpf)) {
                    return false;
                }

                function validaDigitoCpf(pos) {
                    let soma = 0;
                    for (let i = 1; i <= pos; i++) {
                        soma = soma + parseInt(cpf.substring(i - 1, i)) * ((pos + 2) - i);
                    }
                    let resto = (soma * 10) % 11;
                    if ((resto == 10) || (resto == 11)) {
                        resto = 0;
                    }
                    if (resto != parseInt(cpf.substring(pos, (pos + 1)))) {
                        return false;
                    }
                    return true;
                }

                if (!validaDigitoCpf(9) || !validaDigitoCpf(10)) {
                    return false;
                }
                return true;
            },
            changeHasCNPJ() {
                this.cnpj =  !this.cnpj;
                this.document = '';
            },
            changeCidadeRegiaoID(event) {
                const _value = event.target.value;
                const city = this.cidades.filter(cidade => cidade.município === _value)
                if(_value && city) {
                    this.regiao_id = city[0].regiao_id
                    this.cidade_id = city[0].cidade_id
                }
            },
            link() {
                const links = document.querySelectorAll('.copyright a');
                
                links.forEach(link => { link.setAttribute('target', '_blank')});
            }
        },
        mounted() {
            this.link();
            document.getElementById('idGA_lead').value = this.getUUID();
            setTimeout(() => {
                this.getUTMzz();
            }, 1000);
        },
        async setup() {
            const active_level = localStorage.getItem('active_level') ?  localStorage.getItem('active_level') :  'Visitante';  
            const active_level_short = localStorage.getItem('active_level_short') ?  localStorage.getItem('active_level_short') : 'visitante';
            const api_url = process.env.VUE_APP_STRAPI_API_URL;        
            const strapi_url = process.env.VUE_APP_STRAPI_URL;     
            const res = await axios.get(`${api_url}/footer?populate=*`);                                          
            const footer = res.data.data.attributes;
            const collumn1 = res.data.data.attributes.collumn1;
            const collumn2 = res.data.data.attributes.collumn2;            
            const collumn2_beneficiario = res.data.data.attributes.collumn2_footer_beneficiario;                                 
            return {
                active_level,
                active_level_short,
                footer,
                collumn1,
                collumn2,
                collumn2_beneficiario,
                strapi_url,
            }               
        }
    }
</script>
<style lang="styl">
button:disabled {
    background-color: grey !important;
    cursor: not-allowed;
    opacity: 0.7;
}
.logo_footer {
    margin-bottom: 30px;
    border-bottom: 1px solid #fff;
    padding-bottom:15px;
    @media $large-mobile {
        padding: 20px;
    }
    img {
        width: 89px;
    }
}
.registro {
    cursor: pointer;
}
.footer-site {
    background:#595758;
    
    .copyright {
        background:#3B3B3A;
        padding:30px 0;
    }
    a  {
        color:inherit;
    }
}

h2.title-form {
    color:#fff ;
    line-height: 25px;
    margin-bottom:30px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;    
    @media $mobile-max {
        font-size: 20px;
    }
}
.description_footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
}
.intro-form {
    color:#fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
}
.form-footer {
    color:#fff;
    .input-group {
        display:flex;
        justify-content:space-between;
        gap:10px;
        @media $large-mobile {
            flex-direction: column;
        }
    }    
    
    .aceite {
        margin-top: 34px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        
    }
    .possui-cnpj + label{
        width:40%; 
        padding:0;
        align-self:center;
    }
    .input-submit {
        display:flex;
        justify-content:space-between;
        gap:10px;    
        margin-top:40px;    
        button {
            border:0;
            background: #D5086E;
            border-radius: 100px;
            color:#fff;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            padding:15px 100px;
            @media $large-mobile {
                margin-top: 20px;
            }
            &:hover {
                background: #f60d81;
            }
        }
    }

    input, 
    select {
        background: #FFFFFF;
        border: 2px solid #ABABAB;
        border-radius: 100px;
        padding:15px 10px;
        width:100%;
        margin:10px 0;
    }    
    input[type="checkbox"] {
        display:none;        
        &+label {            
            cursor:pointer;
            display:flex;
            align-items:center; 
            @media $large-mobile {
                align-self: flex-start;          
            }        
            align-self: center;          

            &:before {
                content:'';                
                background:#FFF;
                left:0;
                top:0;
                width:30px;
                height:30px;
                border:2px solid #ABABAB;
                border-radius:100px;
                flex-shrink:0;
                margin-right:10px;                
            }                 

        }
        &:checked {
            &+label {
                &:before {
                    background:#D5086E;
                }
            }
        }
    }
}
#hull{    
    padding-top:70px;    

    .ftgrid{
        align-items: flex-start
        display: grid;
        gap: 90px;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            padding: 20px;
            align-items: flex-start !important;
        }
        .ftlogo{
            margin-bottom: 70px;
            max-width: 195px;
            width: 100%;

            @media $mobile-max{
                margin-bottom: 30px;
                max-width: 175px;
            }
        }

        .left{
            .social-midia{
                p{
                    margin-bottom: 20px;
                    margin-top: 19px;
                }    
            }
        }

        .right{

            p:last-child{
                margin-bottom: 0px;
            }

            ul{
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                li:first-child:before{
                    display: none;
                }

                li:before{
                    display: inline-flex;
                    font-size: 16px;
                    margin-right: 5px;
                }

                br{
                    display:  none;
                }

                a:hover{
                    color:$blue;
                }
            }

             @media $mobile-max{
                text-align: center;

                h3{
                    margin-top: 19px;
                }

                br{
                    display: none;
                }

                 ul{
                    display: flex;

                    li:before{
                        display:none;
                    }
                }

                li{
                    br{
                        display: block;
                    }
                }

                .sm-mb-4{
                    margin-bottom: 40px;
                }
            }
        }

        @media $mobile-max{
            align-items:center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 17px;
        }
    }

    .header-selected{
        color: $blue !important;
    }

    .copyright{
        margin-top: 40px;    
    }
}
</style>
